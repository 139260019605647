<div><img src="assets/iPool.png">
<br />
The <a href="https://fitmaxipool.com/">iPool</a> (original) allows for in-place swimming with a tether. We were originally looking at endless pools but are now persuing this for two reasons:
<ul>
  <li>It is way less expensive</li>
  <li>This can be torn down and moved to another location</li>
</ul>
The iPool with the heater is $3,159.00. Funding received for this gift is $0.00.
<br />
<progress value="0" max="3159">0</progress>
</div>
