import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ariel-ballet',
  templateUrl: './ariel-ballet.component.html',
  styleUrls: ['./ariel-ballet.component.scss']
})
export class ArielBalletComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
