import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socialization',
  templateUrl: './socialization.component.html',
  styleUrls: ['./socialization.component.scss']
})
export class SocializationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
