<div><h2>Ariel Church Wishlist</h2><br/>
    <table>
      <tr>
        <td></td>
        <td>Roman Catholic Daily Missal (1962)</td>
        <td>$60</td>
        <td><meter value="0" max="60"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td><a href="https://www.amazon.com/Travel-Makeup-Bags-Cosmetic-Organizer/dp/B0CFQPTGN7/ref=sr_1_17?crid=DJ6Z6XI584M6&dib=eyJ2IjoiMSJ9.0Xt4hjKL5obOZqIfF1sAzdSOO0BVd-j98fFKaxwIabMtUday2jGZHvvxRRxjn-p1yvKucR3IZpkfdIHnAiQSjGyugLelh2ISZuCVkBmGaHozJsRJAUiCc0HlmipOCXxiamT7rej5Py6qp5IpbPA6jzhDTZO9AIE8yQpxFY-pDvSRlk903RIBSgXXTB4JYD9qmuji4eYsLItakPJrkN1z7uRoODhdYE0ctWtNSBLW6oxI6x-a9DrrQFl84WJAH1nGyl2pM8Vyi2EfZINXO2eJd9GEV5aWvV8hoNmoJznWCqQ.D3FdfneF_RICM344akAT4FKftvgu9wMEAUO4VQkIfvA&dib_tag=se&keywords=makeup%2Bbags&qid=1728521390&rnid=386662011&sprefix=makeup%2Bbags%2Caps%2C101&sr=8-17&th=1">Makeup Bag</a></td>
        <td>$20</td>
        <td><meter value="0" max="20"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td>Earrings
          <ul>
            <li><a href="https://www.amazon.com/Pairs-Earrings-Hypoallergenic-Zirconia-Stainless/dp/B0D567L5JP/ref=sr_1_5?crid=3SO5OG7KH18KJ&dib=eyJ2IjoiMSJ9.nhVs8LSEWS0kSnLjlZ0BJm1fhbZGvdS_FyzWHvHGH35rPp_57URnVBCTWElC6XOOBnezgWM9xqV1oOEYzH_ze_Ot8zDlSYawSN53cjNzh8kaSyzDHs2JFZARklrFswFLW2V67SwFUkmxh9nk14WYcyx37L288L_JUQRML1_ko27LV-5KELPphEa5UbuP15ncxUw8z_eIXrrxhStc4JOEhNP52_1ixfJUEFwyvvBVY15bbkrFIaAVsRKSX5KeU6GnxK6IRSSwXHUmvPT5c9veNHPuhm9d3TJeYIylDdlZCtg.D61d9CE7VpUsSeXjsNgAz-rVTWfxBl-SlN4DG0Au5EI&dib_tag=se&keywords=earrings%2Bfor%2Bwomen%2Bstuds&qid=1728698718&sprefix=earrings%2Bfor%2Bwomen%2Bstuds%2Caps%2C126&sr=8-5&th=1">Example 1</a></li>
            <li><a href="https://www.amazon.com/MOROTOLE-Earrings-Sterling-Hypoallergenic-Lightweight/dp/B0D6BK4D5V/ref=sr_1_2_sspa?crid=3DCKNSNS66Z9P&dib=eyJ2IjoiMSJ9.7ZFosZ5AbCeExaEGiHDSTAbrjsbpwv2T9CGNdEbR14W0YIeEysLxK91XM6-nvpj4T3_CrZBncYZ1Wbyhp4TpZqTfaMthC_7xJGktlt2WftqYr2ZlssXNWgf4qQgTGjmez-ug31Stpjd70P4dpA5_TIW4682X25fIunphdJ9hDgqw0mMPgiszmhZVZ1KMqHAsl_I04i5p4U4B-ENVVzz2N_jF4fHHQtV6_QdeHzzDVBNOCeFPn67g-hF_PP6c14Zhs0-jvCluXk3KvmC0fsMNg9vycHSct6qiz0iABTzbZ70.KBW4Ket0Qs3eLNeZAPrPlngvIbrEOw2G40ipCJAa32o&dib_tag=se&keywords=earrings%2Bfor%2Bwomen%2Bstuds%2Bset%2Bhypoallergenic&qid=1728698844&sprefix=earrings%2Bfor%2Bwomen%2Bstuds%2B%2Caps%2C102&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1">Example 2</a></li>
            <li><a href="https://www.amazon.com/Sterling-Silver-Earrings-Hypoallergenic-Jewelry/dp/B09GFJ8HFP/ref=sxin_37_pa_sp_search_thematic_sspa?content-id=amzn1.sym.cf78a22c-e90f-46b3-9c78-94568bffa550%3Aamzn1.sym.cf78a22c-e90f-46b3-9c78-94568bffa550&cv_ct_cx=womens%2Bstud%2Bearrings&dib=eyJ2IjoiMSJ9.lXTVjfGqTLoe1O3x3PJQu9ZX1Guoey9jttetERyQSYRvz6UFu2EI8s9ksghxXiQoI75TRFH4vKMdxtWI-b5w_Q.74RKZ1N9Th1FnWlLiOfDd1tu5WCAg7_AcGugu1UP7Y8&dib_tag=se&keywords=womens%2Bstud%2Bearrings&pd_rd_i=B08CHH2LHV&pd_rd_r=72619850-d05b-47e0-b99d-d5bf693ee420&pd_rd_w=nLf49&pd_rd_wg=5Y2CE&pf_rd_p=cf78a22c-e90f-46b3-9c78-94568bffa550&pf_rd_r=FGCP1PZYQ2QHR355J13W&qid=1728579814&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sr=1-4-7efdef4d-9875-47e1-927f-8c2c1c47ed49-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9zZWFyY2hfdGhlbWF0aWM&th=1">Example 3</a></li>
            <li><a href="https://www.amazon.com/Plated-Sterling-Earring-Hypoallergenic-Earrings/dp/B091TC47T4/ref=sr_1_94?content-id=amzn1.sym.f7816d88-9c66-47a2-b962-9bef15019ff1%3Aamzn1.sym.f7816d88-9c66-47a2-b962-9bef15019ff1&dib=eyJ2IjoiMSJ9.Ma-e9sr1NY1jomFB5gY_8iLz4ky2_8jPFzgFkUBLPoFrGAF3LS6Cd_e8F1xknzE3YZ4pgH4lpXGjfR1i5f2s0fBLOzx6qZcpuuGHAgA242zx-B5VAG-L2MZb34vN_FdkTv3QjrfdGia7LBYhk-HOM0tMKvqi-3j7Vrxki5RYF9JNBkILSoaLXsICecNQFBBc0oqoyHmopRrqf0GF-ashLn9YEyK8-eTpIGFZ9zy9QO4FgQcu7_kDz89ZPZKdgY88EdcKLna2u05xXQwek4v27AT6uoZzsBdSe6zBu-vulDA.i9tiwQ3eHTD0IJ7MFk3VqzJ-TWw5iLVuXB2wAdK1ZJQ&dib_tag=se&keywords=womens%2Bstud%2Bearrings&pd_rd_r=32a08729-8aeb-4d49-b722-4657bd768c56&pd_rd_w=37ydV&pd_rd_wg=ry5yh&pf_rd_p=f7816d88-9c66-47a2-b962-9bef15019ff1&pf_rd_r=PECS3V5R8W178D49BC0G&qid=1728579973&sr=8-94&th=1">Example 4</a></li>
            <li><a href="https://www.amazon.com/SLUYNZ-Sterling-Silver-Earrings-Flowers/dp/B0CNR889VB/ref=sr_1_2_sspa?crid=3SO5OG7KH18KJ&dib=eyJ2IjoiMSJ9.nhVs8LSEWS0kSnLjlZ0BJm1fhbZGvdS_FyzWHvHGH35rPp_57URnVBCTWElC6XOOBnezgWM9xqV1oOEYzH_ze_Ot8zDlSYawSN53cjNzh8kaSyzDHs2JFZARklrFswFLW2V67SwFUkmxh9nk14WYcyx37L288L_JUQRML1_ko27LV-5KELPphEa5UbuP15ncxUw8z_eIXrrxhStc4JOEhNP52_1ixfJUEFwyvvBVY15bbkrFIaAVsRKSX5KeU6GnxK6IRSSwXHUmvPT5c9veNHPuhm9d3TJeYIylDdlZCtg.D61d9CE7VpUsSeXjsNgAz-rVTWfxBl-SlN4DG0Au5EI&dib_tag=se&keywords=earrings%2Bfor%2Bwomen%2Bstuds&qid=1728698718&sprefix=earrings%2Bfor%2Bwomen%2Bstuds%2Caps%2C126&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1">Example 5</a></li>
          </ul>
        </td>
        <td>$500</td>
        <td><meter value="0" max="500"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td><a href="https://www.amazon.com/Trendy-Queen-Oversized-Sweatshirts-Sweaters/dp/B0D1GRX193/ref=sr_1_2_sspa?crid=977JHLU8WQ41&dib=eyJ2IjoiMSJ9.W-zqsHTXnc1Pi5wc5LlSNpmtmwgsRTPFs5yDx6v03nxWRQM-Ns-C5Hcgx_EZBowl3FbGRh1vA7lGJABYOXeQ8IsV78CD4fXvvr05SY7zyAihWOI95RlCoFaBE867XeUYOei_DHc0B5RO7a6wj8wLEQrcTNDceBS6iie1tKvJcTKzy6Ske0q3-VwnOSP6bpeokuvJWV39Wwn9lafMhm5O38g0uapvVTk4zEmmEHFCIaeSnJkECCetlqVbi4R1yCeimTFVS3Urc88GGMmbO2NYx81S5MufDv3LCamDI8YIhKI.x3ba4KRdZ2jcx1qp_l6ZmcOh3P6UQSljNQgEBN_Ifcg&dib_tag=se&keywords=hoodie%2Bfor%2Bwomen%2Bplus%2Bsize&qid=1728698269&sprefix=hoodie%2Bfor%2Bwomen%2Bplus%2Bsize%2Caps%2C111&sr=8-2-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1&psc=1">A hoodie somewhat like this</a></td>
        <td>$30</td>
        <td><meter value="0" max="30"></meter></td>
      </tr>
  </table>
  </div>
