import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ariel-drawing',
  templateUrl: './ariel-drawing.component.html',
  styleUrls: ['./ariel-drawing.component.scss']
})
export class ArielDrawingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
