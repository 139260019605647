<div>
    Tresza would like to write for a living. She has been developing stories with help from her homeschool community.
    <ul>
        <li>Funds for Professional Book Binding<progress value="50" max="500"></progress></li>
      </ul>
    <ul>
      <li>Funds for Atticus (its a writing/book developing program)<progress value="5" max="5000"></progress></li>
    </ul>
    <ul>
      <li>Funds for ISBN Reservation<progress value="1" max="250"></progress></li>
    </ul>
</div>