<div>
    Tresza enjoys dance, soccer and bicycling.
  <ul>
    <li><s>New Bicycle</s><progress value="500" max="500"></progress></li>
  </ul>
  <ul>
    <li>Water Bottle<progress value="1" max="50"></progress></li>
  </ul>
  <ul>
    <li><a href="https://www.trekbikes.com/international/en_IN_TL/equipment/bike-accessories/bike-water-bottles-cages/bike-water-bottle-cages/bontrager-hollow-6-mm-water-bottle-cage/p/00977/?colorCode=black">Water cage</a></li>
  </ul>
  </div>