import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-greenhouse',
  templateUrl: './greenhouse.component.html',
  styleUrls: ['./greenhouse.component.scss']
})
export class GreenhouseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
