import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ongoing',
  templateUrl: './ongoing.component.html',
  styleUrls: ['./ongoing.component.scss']
})
export class OngoingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
