<div>
    The family needs the following clothing replacements:
    <ul>
        <li>Michael
            <ul>
                <li>Church Pants (40" waist / 36" relaxed-fit waist / 29" length)</li>
                <li>Church Shirts (L)</li>
                <li>Shorts (40" waist / 36" relaxed-fit waist)</li>
            </ul>
        </li>
        <li>Ariel
            <ul>
                <li>Black T-Shirt 2XL Women's</li>
            </ul>
        </li>
        <li>
            Tresza
            <ul>
                <li>Medium Women's (No specific needs)</li>
                <li><s>Slippers (Size 7)</s></li>
                <li><s>Sneakers (Size 7)</s></li>
            </ul>
        </li>
        <li>Marcus
            <ul>
                <li>Long-sleeve shirts (Adult Large)</li>
                <li>Polo shirts (Adult Large)</li>
                <li>Sweater</li>
                <li>Pants (39" waist / 22" length) Khakis style - blue/blacl</li>
            </ul>
        </li>
        <li>Caitlin
            <ul>
                <li>Long and short sleeve shirts (16 girls)</li>
                <li>Full length Church dresses</li>
                <li><s>Winter Church Shoes (Kids Size 3) [No exposed areas]</s></li>
            </ul>
        </li>
        <li>Johnathan
            <ul>
                <li>Dress Shirt</li>
                <li>Play pants and church pants Size 6/7 Boys</li>
            </ul>
        </li>
        <li>Kaleo
            <ul>
                <li>Church pants Size 5</li>
                <li>Shirts</li>
                <li><s>Sneakers (Size 9)</s></li>
            </ul>
        </li>
        <li>Gabriel
            <ul>
                <li>Play pants (not jeans, not sweats) Size 3T</li>
            </ul>
        </li>
        <li>Bralynn
            <ul>
                <li>Anything but pants - 12M</li>
            </ul>
        </li>
    </ul>
</div>