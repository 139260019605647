import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IpoolComponent } from './ipool/ipool.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HouseSavingsComponent } from './house-savings/house-savings.component';
import { UpstairsBathComponent } from './upstairs-bath/upstairs-bath.component';
import { HolesComponent } from './holes/holes.component';
import { MrCoolComponent } from './mr-cool/mr-cool.component';
import { DrivewayComponent } from './driveway/driveway.component';
import { KitchenComponent } from './kitchen/kitchen.component';
import { CompleteComponent } from './complete/complete.component';
import { OngoingComponent } from './ongoing/ongoing.component';
import { AssistanceComponent } from './assistance/assistance.component';
import { AimeeWishlistComponent } from './aimee-wishlist/aimee-wishlist.component';
import { ArielArtSuppliesComponent } from './ariel-art-supplies/ariel-art-supplies.component';
import { ArielBalletComponent } from './ariel-ballet/ariel-ballet.component'
import { ArielBusinessComponent } from './ariel-business/ariel-business.component'
import { ArielDrawingComponent } from './ariel-drawing/ariel-drawing.component'
import { ArielPrayerComponent } from './ariel-prayer/ariel-prayer.component'
import { ArielSewingSuppliesComponent } from './ariel-sewing-supplies/ariel-sewing-supplies.component'
import { ArielUniversityComponent } from './ariel-university/ariel-university.component'
import { ArielArtPracticeComponent } from './ariel-art-practice/ariel-art-practice.component';
import { ProtectionComponent } from './protection/protection.component';
import { TreszaSportsComponent } from './tresza-sports/tresza-sports.component';
import { TreszaActingComponent } from './tresza-acting/tresza-acting.component';
import { TreszaWritingComponent } from './tresza-writing/tresza-writing.component';
import { ClothingComponent } from './clothing/clothing.component';
import { SocializationComponent } from './socialization/socialization.component';
import { MarcusSoccerComponent } from './marcus-soccer/marcus-soccer.component';
import { WaterDiscoveryComponent } from './water-discovery/water-discovery.component';
import { GardeningComponent } from './gardening/gardening.component';
import { TreszaPrayerComponent } from './tresza-prayer/tresza-prayer.component';
import { LandscapingComponent } from './landscaping/landscaping.component';
import { AimeeHomemakingComponent } from './aimee-homemaking/aimee-homemaking.component';
import { FireplaceComponent } from './fireplace/fireplace.component';
import { GreenhouseComponent } from './greenhouse/greenhouse.component';
import { SchoolComponent } from './school/school.component';

const routes: Routes = [
  { path: 'iPool', component: IpoolComponent },
  { path: 'house', component: HouseSavingsComponent },
  { path: 'upstairs-bath', component: UpstairsBathComponent },
  { path: 'holes', component: HolesComponent },
  { path: 'MrCool', component: MrCoolComponent },
  { path: 'driveway', component: DrivewayComponent },
  { path: 'kitchen', component: KitchenComponent },
  { path: 'complete', component: CompleteComponent },
  { path: 'ongoing', component: OngoingComponent },
  { path: 'assistance', component: AssistanceComponent },
  { path: 'aimee-wishlist', component: AimeeWishlistComponent },
  { path: 'ariel-art-supplies', component: ArielArtSuppliesComponent },
  { path: 'ariel-art-practice', component: ArielArtPracticeComponent },
  { path: 'ariel-ballet', component: ArielBalletComponent },
  { path: 'ariel-business', component: ArielBusinessComponent },
  { path: 'ariel-drawing', component: ArielDrawingComponent },
  { path: 'ariel-prayer', component: ArielPrayerComponent },
  { path: 'ariel-sewing-supplies', component: ArielSewingSuppliesComponent },
  { path: 'ariel-university', component: ArielUniversityComponent },
  { path: 'protection', component: ProtectionComponent },
  { path: 'tresza-sports', component: TreszaSportsComponent },
  { path: 'tresza-acting', component: TreszaActingComponent },
  { path: 'tresza-writing', component: TreszaWritingComponent },
  { path: 'clothing', component: ClothingComponent },
  { path: 'socialization', component: SocializationComponent },
  { path: 'marcus-soccer', component: MarcusSoccerComponent },
  { path: 'water', component: WaterDiscoveryComponent },
  { path: 'gardening', component: GardeningComponent},
  { path: 'tresza-prayer', component: TreszaPrayerComponent },
  { path: 'landscaping', component: LandscapingComponent },
  { path: 'aimee-homemaking', component: AimeeHomemakingComponent},
  { path: 'fireplace', component: FireplaceComponent},
  { path: 'school', component: SchoolComponent},
  { path: 'greenhouse', component: GreenhouseComponent}
];

@NgModule({
  imports: [MatProgressBarModule,
    RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
