<div class="columned">
  <div [ngClass]="columnClass">
    <div class="lanes">
      <mat-form-field appearance="fill">
        <mat-label>Person</mat-label>
        <mat-select matNativeControl [value]="user" (selectionChange)="newUser($event)">
          <mat-option value="Michael">Michael</mat-option>
          <mat-option value="Aimee">Aimee</mat-option>
          <mat-option value="Ariel">Ariel</mat-option>
          <mat-option value="Tresza">Tresza</mat-option>
          <mat-option value="Marcus">Marcus</mat-option>
          <mat-option value="Caitlin">Caitlin</mat-option>
          <mat-option value="Johnathan">Johnathan</mat-option>
          <mat-option value="Kaleo">Kaleo</mat-option>
          <mat-option value="Gabriel">Gabriel</mat-option>
          <mat-option value="Bralynn">Bralynn</mat-option>
        </mat-select>
      </mat-form-field>
      <span>Filter:</span>
      <div class="laneSelector" matTooltip="Show only the Sports specialization tree" (click)="toggleFilter('Sport')" [ngClass]="getSelectedClass('Sport')">⚽</div>
      <div class="laneSelector" matTooltip="Show only the Art specialization tree" (click)="toggleFilter('Art')" [ngClass]="getSelectedClass('Art')">🎨</div>
      <div class="laneSelector" matTooltip="Show only the Spiritual specialization tree" (click)="toggleFilter('Spiritual')" [ngClass]="getSelectedClass('Spiritual')">🙏</div>
      <div class="laneSelector" matTooltip="Show only the Survival specialization tree" (click)="toggleFilter('Survival')" [ngClass]="getSelectedClass('Survival')">⛺</div>
      <div>
        <ul>
          <li>Pinch to zoom, scrollable canvas</li>
          <li>Hover to see title, click to see details to right →</li>
        </ul>
      </div>
    </div>
    <ngx-graph [draggingEnabled]=false [nodes]="nodes" [links]="edges" [layoutSettings]="{
    orientation: 'TB',
    marginX: 20,
    marginY: 20,
    edgePadding: 100,
    rankPadding: 100,
    nodePadding: 50,
    multigraph: true,
    compound: true
  }">
      <ng-template #nodeTemplate let-node>
        <svg:g class="node">
          <title>{{node.data.description}}</title>
          <svg:polygon [attr.points]="0 + ', ' + node.dimension.height / 2 + ' ' +
            node.dimension.width / 4 + ', ' + 0 + ' ' +
            node.dimension.width * 3 / 4 + ', ' + 0 + ' ' +
            node.dimension.width + ', ' + node.dimension.height / 2 + ' ' +
            node.dimension.width * 3 / 4 + ', ' + node.dimension.height + ' ' +
            node.dimension.width / 4 + ', ' + node.dimension.height + ' ' +
            0 + ', ' + node.dimension.height / 2 + ' '
            " [attr.fill]="node.data.colorOverride">
          </svg:polygon>
          <svg:a [attr.href]="node.data.route">
            <svg:text alignment-baseline="central" [attr.x]="node.dimension.width / 4"
              [attr.y]="node.dimension.height / 2">
              {{node.label}}
            </svg:text>
          </svg:a>
        </svg:g>
      </ng-template>
      <ng-template #defsTemplate>
        <svg:marker id="arrow" refX="2" refY="1" markerWidth="2" markerHeight="2" orient="auto">
          <svg:rect [attr.width]="2" [attr.height]="2" fill="rgb(104,104,104)" class="arrow-head" />
        </svg:marker>
      </ng-template>
      <ng-template #linkTemplate let-link>
        <svg:g class="edge">
          <svg:path class="line" stroke-width="6" [attr.stroke]="link.data.pathColor"></svg:path>
        </svg:g>
      </ng-template>
    </ngx-graph>
  </div>
  <div class="column">
    <router-outlet></router-outlet>
  </div>
</div>
