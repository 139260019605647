import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ipool',
  templateUrl: './ipool.component.html',
  styleUrls: ['./ipool.component.scss']
})
export class IpoolComponent implements OnInit {

  progress = 20;

  constructor() { }

  ngOnInit(): void {
  }

}
