<div>
<h2>Kitchen Remodel</h2>
<h3>Current Issues</h3>
<ul>
  <li>Drafts behind cupboards on South Wall</li>
  <li>Floor Falling Apart</li>
  <li>Ceiling issues from bathroom</li>
  <li>Wallpaper peeling</li>
  <li>Broken counter</li>
  <li>Dishwasher mounting</li>
  <li>Limited walkway / interaction with guests</li>
</ul>
Complete! <progress value="40000" max="40000"></progress>
</div>
