import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'LifeSkillTree';

  edges = [];
  nodes = [];
  data = [];
  route = "";

  rawData = [
    {
      id: '1',
      icon: '🏡',
      description: 'Be Financially Able to Move to small home upon persecution',
      progress: 100,
      people: [
        'Michael',
        'Aimee'
      ],
      categories: [
        'Survival'
      ],
      detail: 'house'
    },
    {
      id: '2',
      icon: '🏡',
      description: 'Be Financially Able to Move to nice home upon persecution',
      progress: 100,
      people: [
        'Michael',
        'Aimee'
      ],
      categories: [
        'Survival'
      ],
      prerequisites: [
        '1',
      ],
      detail: 'house'
    },
    {
      id: '3',
      icon: '🏡',
      description: 'Be Financially Able to Move to homestead with acreage upon persecution',
      progress: 100,
      people: [
        'Michael',
        'Aimee'
      ],
      categories: [
        'Survival'
      ],
      prerequisites: [
        '2',
      ],
      detail: 'house'
    },
    {
      id: '4',
      icon: '🏡',
      description: 'Be Financially Able to Move to nice homestead w/ acreage upon persecution',
      progress: 100,
      people: [
        'Michael',
        'Aimee'
      ],
      categories: [
        'Survival'
      ],
      prerequisites: [
        '3',
      ],
      detail: 'house'
    },
    // {
    //   id: '5',
    //   icon: '🏊',
    //   description: 'Have a swimming pool in order to exercise and get in shape',
    //   progress: 0,
    //   prerequisites: [
    //     '2',
    //   ],
    //   people: [
    //     'Michael',
    //     'Aimee'
    //   ],
    //   categories: [
    //     'Sport',
    //     'Survival'
    //   ],
    //   detail: 'iPool',
    // },
    {
      id: '6',
      icon: '🛁',
      description: 'Repair Leaks in Upstairs Bathroom',
      progress: 100,
      prerequisites: [
        '1',
      ],
      people: [
        'Michael'
      ],
      categories: [
        'Survival'
      ],
      detail: 'upstairs-bath'
    },
    {
      id: '7',
      icon: '🚗',
      description: 'Replace Driveway',
      progress: 0,
      prerequisites: [
        '4',
      ],
      people: [
        'Michael'
      ],
      categories: [
        'Survival'
      ],
      detail: 'driveway',
    },
    {
      id: '8',
      icon: '💧',
      description: 'Fix Drywall Ceilings',
      progress: 100,
      prerequisites: [
        '2', '6'
      ],
      people: [
        'Michael',
        'Marcus'
      ],
      categories: [
        'Survival'
      ],
      detail: 'holes',
    },
    {
      id: '9',
      icon: '🔪',
      description: 'Remodel Kitchen',
      progress: 100,
      prerequisites: [
        '4', '8'
      ],
      people: [
        'Michael',
        'Aimee'
      ],
      categories: [
        'Spiritual',
        'Survival'
      ],
      detail: 'kitchen',
    },
    // {
    //   id: '10',
    //   icon: '❄',
    //   description: 'Mr. Cool Installation on addition',
    //   progress: 0,
    //   prerequisites: [
    //     '2'
    //   ],
    //   people: [
    //     'Michael'
    //   ],
    //   categories: [
    //     'Survival'
    //   ],
    //   detail: "MrCool",
    // },
    {
      id: '11',
      icon: '🚲',
      description: 'Obtain Bicycle',
      progress: 100,
      people: [
        'Michael'
      ],
      categories: [
        'Sport'
      ],
      detail: "complete",
    },
    {
      id: '12',
      icon: '🏍',
      description: 'Replace Bicycle Helmet',
      progress: 100,
      prerequisites: [
        '11'
      ],
      people: [
        'Michael'
      ],
      categories: [
        'Sport'
      ],
    },
    {
      id: '13',
      icon: '🚲',
      description: 'Go on Bicycle ride daily',
      prerequisites: [
        '12'
      ],
      progress: 0,
      people: [
        'Michael'
      ],
      categories: [
        'Sport'
      ],
      detail: 'ongoing'
    },
    {
      id: '14',
      icon: '🎹',
      description: 'Obtain Keyboard for Mac',
      progress: 100,
      people: [
        'Michael'
      ],
      categories: [
        'Art'
      ],
      detail: "complete",
    },
    {
      id: '15',
      icon: '🎼',
      description: 'Practice Music Daily',
      progress: 0,
      prerequisites: [
        '14'
      ],
      people: [
        'Michael'
      ],
      categories: [
        'Art'
      ],
      detail: "ongoing",
    },
    {
      id: '16',
      icon: '📖',
      description: 'Terror of Demons',
      progress: 100,
      prerequisites: [
        '17', '18', '19'
      ],
      people: [
        'Michael'
      ],
      categories: [
        'Spiritual'
      ],
      detail: "complete",
    },
    {
      id: '17',
      icon: '🙏',
      description: 'Morning Prayer Daily',
      progress: 100,
      people: [
        'Michael'
      ],
      categories: [
        'Spiritual'
      ],
      detail: "ongoing",
    },
    {
      id: '18',
      icon: '🙏',
      description: 'Evening Prayer Daily',
      progress: 50,
      people: [
        'Michael'
      ],
      categories: [
        'Spiritual'
      ],
      detail: "ongoing",
    },
    {
      id: '19',
      icon: '📿',
      description: 'Daily Rosary',
      progress: 75,
      people: [
        'Michael'
      ],
      categories: [
        'Spiritual'
      ],
      detail: "ongoing",
    },
    {
      id: '20',
      icon: '🏫',
      description: 'Raise the next generation',
      progress: 50,
      people: [
        'Aimee'
      ],
      prerequisites: [
        '21', '22'
      ],
      categories: [
        'Spiritual', 'Art'
      ],
      detail: "ongoing",
    },
    {
      id: '21',
      icon: '🔋',
      description: 'Assistance and Relief',
      progress: 0,
      people: [
        'Aimee'
      ],
      categories: [
        'Spiritual', 'Art'
      ],
      detail: "assistance"
    },
    {
      id: '22',
      icon: '📋',
      description: 'Wishlist',
      progress: 10,
      people: [
        'Aimee'
      ],
      categories: [
        'Spiritual', 'Art'
      ],
      detail: "aimee-wishlist"
    },
    {
      id: 'Ariel1',
      icon: '🎨',
      description: 'Art Supplies',
      progress: 50,
      categories: [
        'Art'
      ],
      people: [
        'Ariel'
      ],
      detail: "ariel-art-supplies",
    },
    {
      id: 'Ariel2',
      icon: '👚',
      description: 'Sewing Supplies',
      progress: 0,
      people: [
        'Ariel'
      ],
      categories: [
        'Art', 'Survival'
      ],
      detail: "ariel-sewing-supplies",
    },
    {
      id: 'Ariel3',
      icon: '🎓',
      description: 'University for Business',
      progress: 0,
      people: [
        'Ariel'
      ],
      prerequisites: [
        'Ariel1'
      ],
      categories: [
        'Survival'
      ],
      detail: "ariel-university",
    },
    {
      id: 'Ariel4',
      icon: '🏪',
      description: 'Sewing Entrepreneur',
      progress: 0,
      people: [
        'Ariel'
      ],
      prerequisites: [
        'Ariel3', 'Ariel2'
      ],
      categories: [
        'Survival'
      ],
      detail: "ongoing",
    },
    // {
    //   id: 'Ariel5',
    //   icon: '🖌',
    //   description: 'Practice Painting',
    //   progress: 0,
    //   people: [
    //     'Ariel'
    //   ],
    //   prerequisites: [
    //     'Ariel1'
    //   ],
    //   categories: [
    //     'Art'
    //   ],
    //   detail: "ariel-art-practice",
    // },
    {
      id: 'Ariel6',
      icon: '🩰',
      description: 'Ballet',
      progress: 10,
      people: [
        'Ariel'
      ],
      categories: [
        'Sport'
      ],
      detail: "ariel-ballet",
    },
    {
      id: 'Ariel7',
      icon:'🙏',
      description: 'Prayer Supplies',
      progress: 20,
      people: [
        'Ariel'
      ],
      categories: [
        'Spiritual'
      ],
      detail: "ariel-prayer",
    },
    {
      id: 'Tresza4',
      icon:'🙏',
      description: 'Prayer Supplies',
      progress: 0,
      people: [
        'Tresza'
      ],
      categories: [
        'Spiritual'
      ],
      detail: "tresza-prayer",
    },
    {
      id: 'Tresza1',
      icon:'⚽',
      description: 'Sports Equipment',
      progress: 50,
      people: [
        'Tresza'
      ],
      categories: [
        'Sport'
      ],
      detail: "tresza-sports",
    },
    {
      id: 'Tresza2',
      icon:'🎭',
      description: 'Acting',
      progress: 0,
      people: [
        'Tresza'
      ],
      categories: [
        'Art'
      ],
      detail: "tresza-acting",
    },
    {
      id: 'Tresza3',
      icon:'✍',
      description: 'Writing',
      progress: 20,
      people: [
        'Tresza'
      ],
      categories: [
        'Survival', 'Spiritual'
      ],
      detail: "tresza-writing",
    },
    {
      id: 'Shared2',
      icon:'👕',
      description: 'Clothing',
      progress: 0,
      people: [
        'Ariel', 'Tresza', 'Michael', 'Marcus', 'Caitlin', 'Johnathan', 'Kaleo', 'Gabriel', 'Bralynn'
      ],
      categories: [
        'Survival'
      ],
      detail: "clothing",
    },
    {
      id: 'Shared1',
      icon:'🛡',
      description: 'Protector',
      progress: 100,
      people: [
        'Michael',
        'Aimee',
        'Ariel'
      ],
      categories: [
        'Survival'
      ],
      detail: "protection",
    },
    {
      id: 'Shared3',
      icon:'🌲',
      description: 'Socialization and Family Tie Building',
      progress: 0,
      people: [
        'Michael',
        'Aimee',
        'Ariel',
        'Tresza',
        'Marcus',
        'Caitlin',
        'Johnathan',
        'Kaleo',
        'Gabriel'
      ],
      categories: [
        'Survival'
      ],
      detail: "socialization",
    },
    {
      id: 'marcus1',
      icon:'⚽',
      description: 'Soccer',
      progress: 50,
      people: [
        'Marcus',
      ],
      categories: [
        'Sport'
      ],
      detail: "marcus-soccer",
    },
    {
      id: 'marcus2',
      icon:'🪵',
      description: 'Woodworking',
      progress: 0,
      people: [
        'Marcus',
      ],
      categories: [
        'Art'
      ],
      detail: "marcus-wood",
    },
    {
      id: 'water',
      icon:'💧',
      description: 'Water',
      progress: 100,
      people: [
        'Johnathan',
        'Kaleo',
        'Gabriel'
      ],
      categories: [
        'Survival'
      ],
      detail: "water",
    },
    {
      id: 'gardening',
      icon:'🌽',
      description: 'Gardening Skills',
      progress: 100,
      people: [
        'Michael',
        'Aimee',
        'Tresza',
        'Johnathan',
       ],
      categories: [
        'Survival'
      ],
      detail: "gardening",
    },
    {
      id: 'landscaping',
      icon:'🌽',
      description: 'Ohio Landscaping',
      progress: 0,
      people: [
        'Michael',
       ],
      categories: [
        'Survival'
      ],
      prerequisites: ['4'],
      detail: "landscaping",
    },
    {
      id: 'fireplace',
      icon:'🧱',
      description: 'Fireplace Rebuilds',
      progress: 0,
      people: [
        'Michael',
       ],
      categories: [
        'Survival'
      ],
      prerequisites: ['4'],
      detail: "fireplace",
    },
    {
      id: 'greenhouse',
      icon:'🪟',
      description: 'Greenhouse',
      progress: 0,
      people: [
        'Michael',
       ],
      categories: [
        'Survival'
      ],
      prerequisites: ['landscaping'],
      detail: "greenhouse",
    },
    {
      id: 'aimee-homemaking',
      icon:'🍪',
      description: 'Homemaking',
      progress: 0,
      people: [
        'Aimee',
       ],
      categories: [
        'Art'
      ],
      prerequisites: ['4'],
      detail: "aimee-homemaking",
    },
    {
      id: 'school',
      icon:'🏫',
      description: 'School',
      progress: 0,
      people: [
        'Aimee',
        'Johnathan',
        'Kaleo',
        'Gabriel',
        'Bralynn'
       ],
      categories: [
        'Survival'
      ],
      prerequisites: [],
      detail: "school",
    },
    ];

  columnClass = "oneColumn";

  filters = [];

  user = "Michael";

  constructor(private router: ActivatedRoute, private cd: ChangeDetectorRef) {
    router.url.subscribe(x => this.columnClass = (location.pathname.length > 1) ? "manyColumn" : "oneColumn");
    let user = sessionStorage.getItem("user");
    if (user) {
      this.user = user;
    }
    let filters = sessionStorage.getItem("filters");
    if (filters) {
      this.filters = filters.split(",");
    }
    this.calculateTree();
  }

  toggleFilter(category: string) {
    if (this.filters.includes(category)) {
      this.filters = this.filters.filter(i => i !== category);
    } else {
      this.filters.push(category);
    }
    sessionStorage.setItem("filters", this.filters.join(","));
    this.calculateTree();
  }

  getSelectedClass(category: string) {
    return this.filters.includes(category) ? "selected" : "laneSelector";
  }

  newUser(event: any) {
    this.user = event.value;
    sessionStorage.setItem("user", this.user);
    this.calculateTree();
  }

  calculateTree() {
    let activeCategories = [...this.filters];
    if (!activeCategories.length) {
      activeCategories = ['Sport', 'Art', 'Spiritual', 'Survival'];
    }
    this.data = this.rawData.filter(i => i.people.includes(this.user));
    this.AddItemsInCategories(activeCategories);
    this.AddLinks();
  }

  AddLinks() {
    this.edges = [];
    var startNodes = [...this.nodes]
    for (let node of startNodes) {
      let item = this.data.filter(d => d.id === node.id)[0];
      if (item.prerequisites) {
        for (let preReq of item.prerequisites) {
          let beforeNode = this.rawData.filter(d => d.id === preReq)[0];
          if (this.nodes.filter(n => n.id === preReq).length === 0) {
            // Find and add the node
            this.createNode(beforeNode);
          }
          var edge = {} as any;
          edge.source = preReq;
          edge.target = item.id;
          edge.data = {} as any;
          edge.data.pathColor = beforeNode.progress === 100 ?
            "rgba(0,128,0,0.5)" :
            "rgb(104,104,104)"
          this.edges.push(edge);
        }
      }
    }
  }

  AddItemsInCategories(activeCategories: string[]) {
    this.nodes = [];
    for (let item of this.data) {
      if (this.CategoryIntersection(activeCategories, item.categories)) {
        this.createNode(item);
      }
    }
  }

  private createNode(item: any) {
    let node = {} as any;
    node.id = item.id;
    node.label = item.icon;
    node.data = {} as any;
    if (item.detail) {
      node.data.route = item.detail;
    }
    if (item.description) {
      node.data.description = item.description;
    }
    node.data.colorOverride = this.GrayToGreenForPercentComplete(item.progress);
    this.nodes.push(node);
  }

  CategoryIntersection(activeCategories: string[], categories: string[]) {
    let match = false;
    for (let i of activeCategories) {
      for (let j of categories) {
        if (i === j) {
          match = true;
          break;
        }
      }
      if (match) {
        break;
      }
    }
    return match;
  }

  GrayToGreenForPercentComplete(progress: number): any {
    let color;
    // gray 190, 190, 190
    // yellow 255, 255, 0
    // green 0, 128, 0
    if (progress <= 0) {
      color = "rgba(190,190,190,0.5)";
    } else if (progress <= 50) {
      let smallDiff = 255 - 190;
      let smallScale = smallDiff * progress / 50;
      let small = 190 + smallScale;
      let largeDiff = 190;
      let largeScale = largeDiff * progress / 50;
      let large = 190 - largeScale;
      color = "rgba(" + small + "," + small + "," + large + ",0.5)";
    } else if (progress < 100) {
      let offset = progress - 50;
      let smallDiff = 255 - 128;
      let smallScale = smallDiff * offset / 50;
      let small = 255 - smallScale;
      let largeDiff = 255;
      let largeScale = largeDiff * offset / 50;
      let large = 255 - largeScale;
      color = "rgba(" + large + "," + small + "," + 0 + ",0.5)";
    } else {
      color = "rgba(0,128,0,0.5)";
    }
    return color;
  }

  iconClicked(route: string) {
    this.route = route;
    this.cd.detectChanges();
  }
}



