<div>
   Events with people are always preferred over receiving material goods. Here are some ideas:
  <ul>
    <li>Camping</li>
    <li>Fishing</li>
    <li>Horseback Riding</li>
    <li>Bicycle Trail Trip</li>
    <li>Scenic Train Ride</li>
    <li>Bounce house</li>
    <li>Museum</li>
    <li>Arcade</li>
    <li>Restaurant</li>
    <li>Zoo</li>
  </ul>
</div>