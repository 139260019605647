import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxGraphModule } from '@swimlane/ngx-graph';
import { HouseSavingsComponent } from './house-savings/house-savings.component';
import { UpstairsBathComponent } from './upstairs-bath/upstairs-bath.component';
import { HolesComponent } from './holes/holes.component';
import { MrCoolComponent } from './mr-cool/mr-cool.component';
import { DrivewayComponent } from './driveway/driveway.component';
import { KitchenComponent } from './kitchen/kitchen.component';
import { CompleteComponent } from './complete/complete.component';
import { OngoingComponent } from './ongoing/ongoing.component';
import { AssistanceComponent } from './assistance/assistance.component';
import { AimeeWishlistComponent } from './aimee-wishlist/aimee-wishlist.component';
import { ArielArtSuppliesComponent } from './ariel-art-supplies/ariel-art-supplies.component';
import { ArielDrawingComponent } from './ariel-drawing/ariel-drawing.component';
import { ArielUniversityComponent } from './ariel-university/ariel-university.component';
import { ArielBalletComponent } from './ariel-ballet/ariel-ballet.component';
import { ArielPrayerComponent } from './ariel-prayer/ariel-prayer.component';
import { ArielSewingSuppliesComponent } from './ariel-sewing-supplies/ariel-sewing-supplies.component';
import { ArielBusinessComponent } from './ariel-business/ariel-business.component';
import { ArielArtPracticeComponent } from './ariel-art-practice/ariel-art-practice.component';
import { ProtectionComponent } from './protection/protection.component';
import { TreszaSportsComponent } from './tresza-sports/tresza-sports.component';
import { TreszaActingComponent } from './tresza-acting/tresza-acting.component';
import { TreszaWritingComponent } from './tresza-writing/tresza-writing.component';
import { ClothingComponent } from './clothing/clothing.component';
import { SocializationComponent } from './socialization/socialization.component';
import { MarcusSoccerComponent } from './marcus-soccer/marcus-soccer.component';
import { WaterDiscoveryComponent } from './water-discovery/water-discovery.component';
import { SchoolComponent } from './school/school.component';

@NgModule({
  declarations: [
    AppComponent,
    HouseSavingsComponent,
    UpstairsBathComponent,
    HolesComponent,
    MrCoolComponent,
    DrivewayComponent,
    KitchenComponent,
    CompleteComponent,
    OngoingComponent,
    AssistanceComponent,
    AimeeWishlistComponent,
    ArielArtSuppliesComponent,
    ArielDrawingComponent,
    ArielUniversityComponent,
    ArielBalletComponent,
    ArielPrayerComponent,
    ArielSewingSuppliesComponent,
    ArielBusinessComponent,
    ArielArtPracticeComponent,
    ProtectionComponent,
    TreszaSportsComponent,
    TreszaActingComponent,
    TreszaWritingComponent,
    ClothingComponent,
    SocializationComponent,
    MarcusSoccerComponent,
    WaterDiscoveryComponent,
    SchoolComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxGraphModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    MatProgressBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
