<div>
    Various things to maintain and build up the lot
  <ul>
    <li><s>Electric Chain Saw</s><progress value="300" max="300"></progress></li>
    <li>Fencing for garden<progress value="50" max="2650"></progress>
    <ul>
      <li>10x Chunnel 3-way intersection (9' x6')</li>
      <li>10x Chunnel 26' straight</li>
      <li>10x Garden Gates</li>
      <li>300' Welded Wire (6')</li>
    </ul>
    </li>
    <li>Apple Trees<progress value="100" max="600"></progress></li>
    <li>.22 Rifle with Laser Pointer sight (because rodents won't wait for me to get better at aim)</li>
  </ul>
  </div>