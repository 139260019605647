import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tresza-sports',
  templateUrl: './tresza-sports.component.html',
  styleUrls: ['./tresza-sports.component.scss']
})
export class TreszaSportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
