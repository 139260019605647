import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ariel-art-practice',
  templateUrl: './ariel-art-practice.component.html',
  styleUrls: ['./ariel-art-practice.component.scss']
})
export class ArielArtPracticeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
