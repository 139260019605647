import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upstairs-bath',
  templateUrl: './upstairs-bath.component.html',
  styleUrls: ['./upstairs-bath.component.scss']
})
export class UpstairsBathComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
