import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ariel-university',
  templateUrl: './ariel-university.component.html',
  styleUrls: ['./ariel-university.component.scss']
})
export class ArielUniversityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
