<div><h2>Ariel Beauty Wishlist</h2><br/>
    <table>
    <tr>
      <td><img src="assets/ariel-sew-1.jpg"></td>
      <td><a href="https://www.amazon.com/hz/wishlist/ls/1IN8A5UI2TGT2?ref_=wl_share">Adult Female Dress Form Mannequin 12 Dial Adjustable Fabric Torso for Sewing - Size Large #FH-8</a></td>
      <td>$208</td>
      <td><meter value="0" max="208"></meter></td>
    </tr>
    <tr>
        <td><img src="assets/ariel-sew-2.jpg"></td>
        <td><a href="https://www.amazon.com/hz/wishlist/ls/1IN8A5UI2TGT2?ref_=wl_share">Table for Laying Out Sewing Projects</a></td>
        <td>$75</td>
        <td><meter value="0" max="75"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td><a href="https://www.walmart.com/ip/Coats-Clark-14-Polyester-All-Purpose-Black-Zipper-1-Each/19238022?athbdg=L1600">Sew In Zippers (various lengths)</a></td>
        <td>$24</td>
        <td><meter value="0" max="24"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td><a href="https://fabricwholesaledirect.com/products/fabric-scraps-remnants-strips-box">Sewing Scrap Box</a></td>
        <td>$50</td>
        <td><meter value="0" max="50"></meter></td>
      </tr>

  </table>
