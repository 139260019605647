<div>
<h3>Remodeling the Upstairs Bathroom</h3>
<u>Task List</u>
<ul>
  <li>Re-tile bathtub where water damage occurred</li>
  <ul>
    <li>Remove Tile and old rotted wall board <progress value="100" max="100"></progress></li>
    <li>Add new concrete backer board<progress value="100" max="100"></progress></li>
    <li>Remove old grout with Hydrocloric Acid ($20)<progress value="100" max="100"></progress></li>
    <li>Place tile back onto backer board<progress value="100" max="100"></progress></li>
    <li>Grout tile<progress value="100" max="100"></progress></li>
    <li>Caulk Tub<progress value="100" max="100"></progress></li>
  </ul>
  <li><a href="https://www.lowes.com/pd/allen-roth-Aveley-42-in-Linen-Bathroom-Vanity-Cabinet/1001855336">New Cabinet</a> $650 <progress value="100" max="100"></progress></li>
  <li>Remove small tile floor<progress value="100" max="100"></progress></li>
  <li>Install new <a href="https://www.lowes.com/pd/Style-Selections-3-78-in-Spice-Bamboo-Solid-Hardwood-Flooring-23-8-sq-ft/1001050884">flooring</a> 25 sq. ft. $114<progress value="100" max="100"></progress></li>
</ul>
</div>
