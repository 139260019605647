<div>
  <h2>Ariel Art Supplies Wishlist</h2><br/>
  <table>
    <tr>
      <td><img src="assets/ariel-art-3.jpg"></td>
      <td><a href="https://www.amazon.com/hz/wishlist/ls/1IN8A5UI2TGT2?ref_=wl_share">Detail Paintbrushes</a></td>
      <td>$25</td>
      <td><meter value="0" max="25"></meter></td>
    </tr>
    <tr>
      <td><img src="assets/ariel-art-3.jpg"></td>
      <td><a href="https://www.amazon.com/Pentel-Arts-Pastels-Color-PHN-50/dp/B002ZD1XRO/ref=sr_1_3_sspa?crid=3VLQ4WL1199ZG&keywords=oil%2Bpastel%2Bpencils%2Bfor%2Bartists&qid=1644256903&sprefix=oil%2Bpastel%2B%2Caps%2C124&sr=8-3-spons&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEzNDVPWUZCV09ZTkwyJmVuY3J5cHRlZElkPUExMDIyNDEzMTNPMUpLVllNWjFSNiZlbmNyeXB0ZWRBZElkPUEwMTgyMTM1MUxHWFVRN09QTDhCVyZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU&th=1">Oil Pastels</a></td>
      <td>$45</td>
      <td><meter value="0" max="45"></meter></td>
    </tr>
    <tr>
      <td><img src="assets/ariel-art-3.jpg"></td>
      <td><a href="https://www.amazon.com/Palette-Acrylic-Wooden-Handle-Painting/dp/B088BRSPKW/ref=sr_1_1_sspa?dchild=1&keywords=palette+knives&qid=1631836936&sr=8-1-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExUlhUVzQyNzhNUzBNJmVuY3J5cHRlZElkPUEwMjQzMzQwVDBDQk45QzRBQVo0JmVuY3J5cHRlZEFkSWQ9QTEwMzA0MjAyWTU5UkpNMFc5TjhQJndpZGdldE5hbWU9c3BfYXRmJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ==">Pallete Knife Set</a></td>
      <td>$16</td>
      <td><meter value="0" max="16"></meter></td>
    </tr>
    <tr>
      <td><img src="assets/ariel-art-3.jpg"></td>
      <td>Black Acrylic Paint</td>
      <td></td>
      <td><meter value="0" max="30"></meter></td>
    </tr>
    <tr>
      <td><img src="assets/ariel-art-3.jpg"></td>
      <td>White Acrylic Paint</td>
      <td></td>
      <td><meter value="0" max="30"></meter></td>
    </tr>
  </table>
</div>