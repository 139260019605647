<div><h2>Tresza Church Wishlist</h2><br/>
    <table>
    <tr>
        <td><!-- image --></td>
        <td><a href="https://www.amazon.com/dp/B0D8Q9LJRN/ref=twister_B0D9LPBT6C?th=1&psc=1">Church Coat</a></td>
        <td><!-- cost --></td>
        <td><meter value="0" max="100"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td>Purse</td>
        <td>$50</td>
        <td><meter value="0" max="50"></meter></td>
      </tr>
      <tr>
        <td></td>
        <td><a href="https://angeluspress.org/products/1962-roman-catholic-daily-missal">Roman Catholic Daily Missal (1962)</a></td>
        <td>$60</td>
        <td><meter value="0" max="60"></meter></td>
      </tr>
  </table>
  </div>
