<div><h2>Wishlist Items</h2><br/>
<table>
    <tr>
      <td></td>
      <td>Masses</td>
      <td></td>
      <td><meter value="5" max="100"></meter></td>
    </tr>
    <tr>
      <td></td>
      <td>Darn Tough Socks Women's M</td>
      <td></td>
      <td><meter value="5" max="100"></meter></td>
    </tr>
    <tr>
      <td></td>
      <td><a href="https://www.amazon.com/LUOLAO-Crusher-Grinding-Efficient-Labor-Saving/dp/B094ZQ54CJ/ref=sr_1_1_sspa?crid=HBCJBYCKA9V4&dib=eyJ2IjoiMSJ9.eFvQu2_UYIH1IFd_kfM2mK8gyAYTdVvrbpYTbeteKET2dt4y_4n5f8YlNjHXI3EUaeOHHXGz9Kdt4P7xDdpL_SFi983g-LLz2QOpZHb9KEEjp7ZcrGIxD-Y7wCPa7lZ1IewLz45tE-rU-SY8Imd93N-l4frQQwJg8BJRcC3nlV8HN_UslgCOOh8OOjzr7TasvQ5QZSROqYm2jAfR5bcVv_hLIh80Ll9D25Ax-aqoZszRamr_ynJF11w_sAHCaaqvhj1QS7R7RnNaBU0IeSPvV14z1fS7NYQGJsqAfFM5ybo.GT4XcMGprYkf9vM4GKHvbXRA-Re-WoXqZKkWAOAxyJw&dib_tag=se&keywords=stone%2Bmortar%2Band%2Bpestle&qid=1729182791&sprefix=stone%2Bmortar%2Band%2Bpestle%2Caps%2C82&sr=8-1-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9hdGY&th=1">Mortar and Pestle Set</a></td>
      <td></td>
      <td><meter value="5" max="100"></meter></td>
    </tr>
</table>
</div>
