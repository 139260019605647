import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gardening',
  templateUrl: './gardening.component.html',
  styleUrls: ['./gardening.component.scss']
})
export class GardeningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
