<div>
    Repair fireplaces within the house
  <ul>
    <li>Repoint office chimney</li>
    <li>Repoint kitchen/bathroom chimeny</li>
    <li>Roof maintenance for chimneys</li>
    <li>Repoint fireplace (per fire rating) chimney</li>
    <li>Install fireplace flue</li>
    <li>Bring fireplace chimeny through the roof again</li>
  </ul>
  </div>